import { Fancybox } from "@fancyapps/ui";
(function () {
    'use strict';
    /**
     * Converts a semicolon-separated metadata string into an array of trimmed substrings.
     *
     * @param {string} metadataString - The semicolon-separated metadata string.
     * @returns {string[]} An array of trimmed substrings from the metadata string.
     */
    const parseMultiToArray = (metadataString) => {
        return metadataString.split(';').map(s => s.trim()).filter(s => s !== "");
    };


    window.addEventListener('DOMContentLoaded', function () {
        const galleryElements = document.querySelectorAll('.qld__image_gallery__wrapper');
        galleryElements.forEach(galleryElement => {
            const customOptions = parseMultiToArray(galleryElement.dataset.options);
            const fancyOptions = {
                groupAll: true,
                Toolbar: {
                  display: {
                    left: customOptions.includes("infobar") ? ["infobar"] : [],
                    right: [
                      customOptions.includes("zoom") ? "iterateZoom" : "",
                      customOptions.includes("slideshow") ? "slideshow" : "",
                      customOptions.includes("download") ? "download" : "",
                      customOptions.includes("fullscreen") ? "fullscreen" : "",
                      customOptions.includes("thumbs") ? "thumbs" : "",
                      "close"
                    ].filter(item => item !== "")
                  }
                }
              };

            Fancybox.bind(galleryElement, "[data-fancybox] a", fancyOptions);
        });
    });
}());