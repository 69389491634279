(function () {
    'use strict';
    document.addEventListener('DOMContentLoaded', function(e) { 
        const carouselContainers = $(".qld__carousel .carousel");
        carouselContainers.each(function() {
            let slider = $(this);
            let controlsElement = slider.parent().find('.qld__carousel__controls');
            slider.slick({            
                dots: true,
                arrows: true,
                infinite: true,
                autoplay: true,
                autoplaySpeed: 8000,
                slidesToShow: 1,
                adaptiveHeight: true,
                appendDots:slider.parent().find('.qld__carousel__indicators'),
                appendArrows:controlsElement,
                prevArrow:'<button type="button" class="controls-prev"><i class="fa fa-chevron-left"></i></button>',
                nextArrow:'<button type="button" class="controls-next"><i class="fa fa-chevron-right"></i></button>'
            });            
            // if arrows exist then add pause/play button
            let prevElement = controlsElement.find('.controls-prev');
            if (prevElement.length > 0) {
                let pauseButtonElement = $('<button type="button" class="controls-pause"><i class="fa fa-pause"></i></button>').insertAfter(prevElement);
                pauseButtonElement.on('click', function(e) {
                    var button = $(this);
                    if (button.data('state') == 'paused') {
                        slider.slick('slickPlay');
                        button.data('state','play');
                        button.html('<i class="fa fa-pause"></i>');
                    } else {
                        slider.slick('slickPause');
                        button.data('state','paused');
                        button.html('<i class="fa fa-play"></i>');
                    };
                });
            }
        });
    });
}());
