(function () {
    "use strict";

    const Papa = require('papaparse');
    const moment = require('moment');
    require('datatables.net-plugins/sorting/datetime-moment.js');

    /**
     * @module dataTables
     * 
     * Please note the pluralisation. It is named differently to the DT version (dataTable vs dataTables)
     */

    const dataTables = {
        init: function () {
            const wrapperElements = document.querySelectorAll('.qld__data_tables__wrapper');
            wrapperElements.forEach(function (element) {
                const options = {
                    bodyBackground: getBodyBackgroundCheck(element),
                    dateFormat: element.dataset.dateFormat,
                    sortColumn1: element.dataset.sortColumn1,   //FirstColumnToOrder
                    orderColumn1: element.dataset.orderColumn1, //FirstColumnOrder
                    sortColumn2: element.dataset.sortColumn2,
                    orderColumn2: element.dataset.orderColumn2,
                    sortColumn3: element.dataset.sortColumn3,
                    orderColumn3: element.dataset.orderColumn3,
                    globalSearch: parseYesNoToBoolean(element.dataset.globalsearch),
                    columnSearch: parseYesNoToBoolean(element.dataset.columnsearch),
                    filterColumns: parseMultiToArray(element.dataset.filterColumns).map(function (s) { return s - 1 }),
                    hideColumns: parseMultiToArray(element.dataset.hideColumns).map(function (s) { return s - 1 }),
                    pageSize: element.dataset.pageSize,
                    customisations: parseMultiToArray(element.dataset.customisations),
                    csvFileURL: element.dataset.csvSource
                };
                const dataTableManager = new DataTableManager(element, options);

            });

            
            const filterToggle = document.querySelectorAll('.qld__btn--dt-toggle-filter');
            if (filterToggle.length) {
                filterToggle.forEach(function(showToggleButton){
                    showToggleButton.addEventListener('click', function () {
                        if (this.innerText.indexOf('Show') > -1) {
                            this.innerHTML = '<span>Hide filters<i class="fa-light fa-filter-list"></i></span>';
                        } else {
                            this.innerHTML = '<span>Show filters<i class="fa-light fa-filter-list"></i></span>';
                        }
    
                        QLD.accordion.Toggle(showToggleButton);
                    });
                });
            }

            
            document.addEventListener("click", function (event) {
                if (event.target.classList.contains("qld__btn--dt-clear-filters")) {
                    const filterWrapper = event.target.closest(".qld__data_tables__filter_wrapper");
                    if (filterWrapper) {
                        resetFilters(filterWrapper);
                    }
                } else if (event.target.classList.contains("qld__tag--filter-close")) {
                    const filterWrapper = event.target.closest(".qld__data_tables__filter_wrapper");
                    const filterTag = event.target.closest(".qld__tag--filter");

                    if (filterTag && filterWrapper) {
                        const dataSelect = filterTag.dataset.select;
                        if (dataSelect) {
                            const selectElement = filterWrapper.querySelector(`.qld__select--filter[data-name="${dataSelect}"]`);
                            if (selectElement) {
                                selectElement.value = '';
                                const changeEvent = new Event('change');
                                selectElement.dispatchEvent(changeEvent);
                            }
                        }
                    }
                }
            });
            
            function resetFilters(filterWrapper) {
                // Reset select values to nothing
                filterWrapper.classList.remove('filters-active');
                const selectElements = filterWrapper.querySelectorAll(".qld__select--filter");
                selectElements.forEach(function(selectElement) {
                    selectElement.value = "";
                    const changeEvent = new Event('change');
                    selectElement.dispatchEvent(changeEvent);
                });
            
                // Remove all .qld__tag--filter elements
                const tagFilterElements = filterWrapper.querySelectorAll(".qld__tag--filter");
                tagFilterElements.forEach(function(tag) {
                    tag.parentElement.remove();
                });

            }
            
             
        }
    };

    class DataTableManager {
        constructor(wrapperElement, options) {
            this.wrapperElement = wrapperElement;
            this.options = options;
            this.dataTableInstance = null; 
            this.init();
        }

        init() {
            if (this.wrapperElement.hasAttribute('data-csv-source')) {
                this.dataTableCsv();
            } else {
                this.dataTableHtml();
            }
        }

        initMoment(){
            // Get the date formats and add them to the dataTable.moment function.
            const dateFormatsArray = this.options.dateFormat.split(";");
            for (let i = 0; i < dateFormatsArray.length; i++) {
                const dateFormat = dateFormatsArray[i].trim();
                $.fn.dataTable.moment(dateFormat);
            }

        }
        initColumnSearch() {
            const self = this;
            const filterColumns = this.options.filterColumns;
            const doColumnSearch = this.options.columnSearch;
            const wrapperElement = this.wrapperElement;
            const numColumns = this.dataTableInstance.columns().count();

            if (!doColumnSearch && filterColumns.length == 0) return;
            const search = $("<tr class='dt-search'></tr>");
            const filterElement = $(wrapperElement).find('.qld__data_tables__filter');
            this.dataTableInstance.columns().every(function () {
                const column = this;
                if (this.visible()) {
                    const index = this.index();
                    const title = $(column.header()).text();
                    const td = $('<td></td>');
                    const filterItem = $(`<div class="filter_item"></div>`);
                    var filterExists = false;
                    if (filterColumns.indexOf(index) >= 0) {
                        const label = $(`<label class="qld__label">${title}</label>`);
                        const field = $(`<select class="qld__select qld__select--filter qld__text-input--block" data-name="col${index}"><option value="">Select ${title}</option></select>`);
                        field.on('change', function (event) {
                            var val = $.fn.dataTable.util.escapeRegex($(this).val());
                            column.search(val ? '^' + val + '$' : '', true, false).draw();
                            field.toggleClass('filtered', val != "");
                            self.updateFilteredBy(label,val);

                        });
                        column.data().unique().sort().each(function (d, j) {
                            const dom = $.parseHTML(d) || [];
                            const clean = dom.map(function (e) { return e.textContent }).join('');
                            if ($('option[value=' + JSON.stringify(clean) + ']', field).length == 0) {
                                field.append('<option value="' + clean + '">' + clean + '</option>')
                            }
                        });
                        const preset = getUrlParam('col' + (index + 1));
                        if (preset) {
                            var options = field.find('option').map(function (i, e) { return e.value }).toArray();
                            if (options.indexOf(preset) >= 0) {
                                field.val(preset);
                                field.trigger('change');
                            }
                        }
                        if (doColumnSearch) {
                            td.append(field);
                        } else {
                            filterItem.append(label);
                            filterItem.append(field);
                            filterElement.append(filterItem);
                        }
                        var filterExists = true;
                    } 
                    if (doColumnSearch) {
                        
                        if(filterExists === false){
                            var field = $('<input type="text" placeholder="Search ' + title + '" style="width:100%" />');
                            field.on('keyup change', function (event) {
                                var val = $(this).val();
                                if (column.search() !== val) {
                                    column.search(val).draw();
                                    field.toggleClass('filtered', val != "");
                                }
                            });
                            td.append(field);
                        } 
                        search.append(td);
                    }

                }
            });

            $(this.dataTableInstance.table().header()).append(search);
            this.updateFilteredBy();
            window.dispatchEvent(new Event('resize'));
        }

        updateFilteredBy() {
            const wrapperElement = this.wrapperElement;

            const filterContainer = wrapperElement.querySelector('.qld__data_tables__filter_wrapper');
            if(!filterContainer) {
                return;
            }
            const selectElements = wrapperElement.querySelectorAll(".qld__select--filter");
            const filtersList = wrapperElement.querySelector(".qld__search__filters-list");
            const clearFiltersButton = filtersList.querySelector('.qld__btn--dt-clear-filters');
            const lastLiElement = clearFiltersButton.parentElement;

            //check if any filters are active
            const selectedFilters = Array.from(selectElements).some(select => select.value !== '');

            // Add or remove the class based on whether there are selected filters
            if (selectedFilters) {
                filterContainer.classList.add('filters-active');
            } else {
                filterContainer.classList.remove('filters-active');
            }


            selectElements.forEach(function(select) {
                const selectedValue = select.value;
                const selectName = select.dataset.name;
                const existingTag = filtersList.querySelector(`.qld__tag--filter[data-select="${selectName}"]`);
                   
                if (selectedValue) {
                     const liItem = `<li>
                            <div class="qld__tag qld__tag--large qld__tag--filter" data-select="${selectName}">
                                ${selectedValue}
                                <button class="qld__tag--filter-close">
                                    <span class="sr-only">Remove ${selectedValue} filter</span>
                                </button>
                            </div>
                        </li>`;
        
                    if (existingTag) {
                        existingTag.parentElement.remove(); 
                    }
                    lastLiElement.insertAdjacentHTML('beforebegin', liItem);
                    
                } else {
                    if (existingTag) {
                        existingTag.parentElement.remove();
                    }
                }
            });
        }

        /**
         * Draws a DataTable within the specified table element using the provided configuration.
         *
         * @param {jQuery|HTMLElement} tableElement - The jQuery object or DOM element of the HTML table in which the DataTable will be drawn.
         * @param {Object} [dataTableOptions={}] - The configuration options for override default ones for the DataTable.
         */
        drawTable(tableElement, dataTableOptions = {}) {

            const self = this;
            const wrapperElement = this.wrapperElement;
            const doGlobalSearch = this.options.globalSearch;
            const customisations = this.options.customisations;
            const hideColumnsConfig = {
                "targets": this.options.hideColumns,
                "visible": false
            };
            

            // Get the column order and sorting values.
            // remove any that are beyond the number of columns availalble
            const numColumns = $(tableElement).find('thead th').length || (dataTableOptions.hasOwnProperty('columns') ? dataTableOptions.columns.length : 0);
            const columnOrderArray = [
                [this.options.sortColumn1 - 1, this.options.orderColumn1 || 'asc'],
                [this.options.sortColumn2 - 1, this.options.orderColumn2 || 'asc'],
                [this.options.sortColumn3 - 1, this.options.orderColumn3 || 'asc']
            ].filter(function (e) {
                const columnIndex = e[0];
                return columnIndex >= 0 && columnIndex < numColumns;
            });


            const defaultOptions = {
                "sScrollX": "100%",
                "sScrollXInner": "100%",
                "bScrollCollapse": true,
                "dom": '<"top"'
                    + (customisations.indexOf('hideXY') < 0 ? 'i' : '')
                    + (doGlobalSearch ? 'f' : '')
                    + '>rt<"bottom"lp><"clear">',
                "pagingType": "simple_numbers",
                "pagingTag": "li",
                "fixedHeader": (customisations.indexOf('fixedHeader') < 0 ? false : true),
                "pageLength": parseInt(self.options.pageSize, 10) || 10,
                "lengthMenu": [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]],
                "order": columnOrderArray,
                "language": {
                    "paginate": {
                        "previous": `<a rel="prev"
                                aria-label="Next page of results" class="qld__search-pagination_link">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" aria-hidden="true" focusable="false"
                                    width="16" height="16" role="img">
                                    <path fill="currentColor"
                                        d="M448 256C448 264.8 440.6 272 431.4 272H54.11l140.7 149.3c6.157 6.531 5.655 16.66-1.118 22.59C190.5 446.6 186.5 448 182.5 448c-4.505 0-9.009-1.75-12.28-5.25l-165.9-176c-5.752-6.094-5.752-15.41 0-21.5l165.9-176c6.19-6.562 16.69-7 23.45-1.094c6.773 5.938 7.275 16.06 1.118 22.59L54.11 240h377.3C440.6 240 448 247.2 448 256z">
                                    </path>
                                </svg><span>Back</span>
                            </a>`,
                        "next": `<a rel="next" aria-label="Next page of results" class="qld__search-pagination_link">
                                    <span>Next</span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" aria-hidden="true" focusable="false" width="16" height="16" role="img"><path fill="currentColor" d="M443.7 266.8l-165.9 176C274.5 446.3 269.1 448 265.5 448c-3.986 0-7.988-1.375-11.16-4.156c-6.773-5.938-7.275-16.06-1.118-22.59L393.9 272H16.59c-9.171 0-16.59-7.155-16.59-15.1S7.421 240 16.59 240h377.3l-140.7-149.3c-6.157-6.531-5.655-16.66 1.118-22.59c6.789-5.906 17.27-5.469 23.45 1.094l165.9 176C449.4 251.3 449.4 260.7 443.7 266.8z"></path></svg>
                                    </a>`,
                    },
                    "sEmptyTable": "No data available in table",
                    "sInfo": "<span class='qld__data-table-item-numbers'><span class='qld__data-table-item-numbers-heading'>Showing:</span> _START_-_END_ of <span class='qld__data-table-item-numbers-total'>_TOTAL_ entries</span></span>",
                    "sInfoEmpty":
                        "<span class='qld__data-table-item-numbers'><span class='qld__data-table-item-numbers-heading'>Showing:</span> 0-0 of <span class='qld__data-table-item-numbers-total'>0 entries</span></span>",
                    "sInfoFiltered": "(filtered from _MAX_ total entries)",
                    "sInfoPostFix": "",
                    "sDecimal": "",
                    "sThousands": ",",
                    "sLengthMenu": "<span>Items per page</span> _MENU_",
                    "sLoadingRecords": "Loading...",
                    "sProcessing": "",
                    "sSearch": `Search:<input type="search" id="qld__data-table__search-field" name="query" class="qld__text-input" autocomplete="off" value=""></input>`,
                    "sSearchPlaceholder": "",
                    "sUrl": "",
                    "sZeroRecords": "No matching records found",
                },
                "columnDefs": [
                    hideColumnsConfig
                ],
                "oClasses": {
                    "sFilterInput": "qld__text-input",
                    "sPageButton": "qld__search-pagination_link ",
                    "sPageButtonActive": "active",
                    "sPageButtonDisabled": "disabled",
                    "sPaging": "dataTables_paginate paging_ qld__search-pagination ",

                },
                "drawCallback": function (settings) {
                    var parentDiv = $(wrapperElement).find("div.qld__search-pagination");
                    var ulElement = $(
                        '<ul class="qld__search-pagination__list"></ul>'
                    );
                    parentDiv.children().appendTo(ulElement);
                    ulElement.appendTo(parentDiv);

                    $(wrapperElement).find(".qld__search-pagination_link").each(function () {
                        var $this = $(this);
                        if (
                            $this.children("a").length === 0 &&
                            $this.children("span").length === 0 &&
                            $this.children("svg").length === 0
                        ) {
                            var textContent = $this.text();
                            $this.html('<a class="num">' + textContent + "</a>");
                        }
                    });

                    $(wrapperElement).find(".qld__search-pagination__list span:not([class])").each(
                        function () {
                            var $span = $(this);

                            // Move the nested <li> elements outside of the <span>
                            $span.before($span.contents());

                            // Remove the empty <span>
                            $span.remove();
                        }
                    );
                    $(wrapperElement).find("li.previous").addClass("prev"); //adding the appropriate class



                },
                "initComplete": function(settings, json) {
                    const observer = new MutationObserver(function(mutationsList, observer) {
                        mutationsList.forEach(function(mutation) {
                            if (mutation.type === 'childList') {
                                mutation.addedNodes.forEach(function(node) {
                                    if (node.nodeType === 1 && node.classList.contains('dtfh-floatingparent')) {
                                        (self.options.bodyBackground ? node.classList.add('qld__body', self.options.bodyBackground) : node.classList.add('qld__body'));
                                        const floatingTable = node.querySelector('table.fixedHeader-floating');
                                        if (floatingTable) {
                                            floatingTable.classList.add('qld__data-table');
                                        }                                        
                                    }
                                });
                            }
                        });
                    });
                    observer.observe(document.body, { 
                        childList: true 
                    });
                }
            };

            this.initMoment();
            const mergedOptions = { ...defaultOptions, ...dataTableOptions };
            this.dataTableInstance = $(tableElement).DataTable(mergedOptions);
            const QLD_DataTable = this.dataTableInstance;

            this.initColumnSearch();

            this.updateFooterClasses();

            QLD_DataTable.on("order.dt", function () {
                let order = QLD_DataTable.order()[0]; // Get the column index being sorted
                $(wrapperElement).find("tfoot tr th").removeClass("sorting_asc sorting_desc sorting sorting_1 sorting_2 sorting_3");
                if(order){
                    $(wrapperElement).find("tfoot tr th:nth-child(" + (order[0] + 1) + ")").addClass("sorting_1");
                }
            });

        }

        updateFooterClasses() {
            const QLD_DataTable = this.dataTableInstance;
            const orderColumns = QLD_DataTable.order();
            orderColumns.forEach(([columnIndex, order], iteratorIndex) => {
                const column = QLD_DataTable.column(columnIndex);
                const headerCell = $(column.header());
                const footerCell = $(column.footer());

                const sortingClass = `sorting_${iteratorIndex + 1}`;
                footerCell.addClass(sortingClass);
            });

        }

        dataTableCsv() {
            const tableWrapper = this.wrapperElement.querySelector('.qld__data-table-content');
            const customisations = this.options.customisations;
            const self = this;
            if (tableWrapper) {
                tableWrapper.style.display = "block";
                const csvTable = $('<table></table>').appendTo(tableWrapper);
                const csvFileURL = this.options.csvFileURL;
                var processCSV = function (results, file) {
                    const tableColumnHeads = results.meta.fields.map(function(c, i){return c});
                    const tableData = results.data; 

                    // Defining variables that are included in the table using the processed data from the functions.
                    let tableColumns = tableColumnHeads.map((column) => {
                        return { data: column, title: column };
                    });

                    self.drawTable(csvTable, {
                        data: tableData ? tableData : [],
                        columns: tableColumns ? tableColumns : [],
                    });
                }

                const papaConfig = {
                    delimiter: ",",
                    quoteChar: '"',
                    skipEmptyLines: true,
                    header: true,
                    download: true,
                   transform: (customisations.indexOf('csvNewLine') < 0 ? null : function(val) {return val.replace(/\n/g,'<br>')}),
                    complete: processCSV
                };
                Papa.parse(csvFileURL, papaConfig);

            }

        }

        dataTableHtml() {
            const tableWrapper = this.wrapperElement.querySelector('.qld__data-table-content');

            if (tableWrapper) {
                tableWrapper.style.display = "block";

                const tableElement = $(tableWrapper).find("table");
                this.drawTable(tableElement);

            }

        }
    }

    /**
     * Look for differnt qld__body colour theme classes applied to the DataTable and add them to the floating header div
     * @param {NodeList} wrapperElements - Node list of elements
     * @returns {string} - Returns the class name
     */
    const getBodyBackgroundCheck = (element) => {
        const parentElement = element.closest('.qld__data_tables');
        if (!parentElement) {
            return '';
        }
        const qldClass = Array.from(parentElement.classList).find(cls => cls.startsWith('qld__body--'));
        return qldClass || '';
    };

    /**
     * Parses a string input and returns a boolean value. Useful for Metadata fields that use Yes,No as their values.
     * @param {string} input - The input string to be parsed.
     * @returns {boolean} - Returns true if input is "yes" (case-insensitive), false otherwise.
     */
    const parseYesNoToBoolean = (input) => /^yes$/i.test(input);

    /**
     * Converts a semicolon-separated metadata string into an array of trimmed substrings.
     *
     * @param {string} metadataString - The semicolon-separated metadata string.
     * @returns {string[]} An array of trimmed substrings from the metadata string.
     */
    const parseMultiToArray = (metadataString) => {
        return metadataString.split(';').map(s => s.trim()).filter(s => s !== "");
    };

    /**
     * Retrieves the value of a query parameter from the current URL.
     *
     * @param {string} sParam - The name of the query parameter to retrieve.
     * @returns {string|boolean|null} The value of the query parameter if found, `true` if the parameter is present without a value, or null if the parameter is not found.
     */
    const getUrlParam = (sParam) => {
        var sURLVariables = window.location.search.substring(1).split('&')
        for (let i = 0; i < sURLVariables.length; i++) {
            var parts = sURLVariables[i].split('=').map(function (s) { return decodeURIComponent(s); });
            if (parts[0].toLowerCase() === sParam.toLowerCase()) {
                return parts[1] === undefined ? true : parts[1];
            }
        }
        return null;
    }


    QLD.dataTables = dataTables;  // different to DT's QLD.dataTable object
    document.addEventListener("DOMContentLoaded", QLD.dataTables.init);

})();