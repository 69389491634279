/**
 * @module accordion2
 */
(function () {
    window.addEventListener('DOMContentLoaded', function () {
        const accordionAllButtons = document.querySelectorAll('.qld__accordion__toggle-btn');
        if (accordionAllButtons.length > 0) {
            QLD.accordion.setToggleState = function(element, state){
                const inverseState = (state==='open') ? 'closed' : 'open';
                const buttonText = (state==='open') ? 'Open all' : 'Close all';
                element.classList.remove(`qld__accordion__toggle-btn--${state}`);
                element.classList.add(`qld__accordion__toggle-btn--${inverseState}`);
                element.textContent = buttonText;
            };

            QLD.accordion.checkToggleState = function(element){
                if(element){
                    const accordionGroup = element.closest('.qld__accordion-group');
                    if(accordionGroup){
                        const toggleButton = accordionGroup.querySelector('.qld__accordion__toggle-btn');
                        if(toggleButton){
                            const allButtons = accordionGroup.querySelectorAll('.qld__accordion__title');
                            const allButtonsOpen = Array.from(allButtons).every(button => button.classList.contains('qld__accordion--open'));
                            const allButtonsClosed = Array.from(allButtons).every(button => button.classList.contains('qld__accordion--closed'));
                            if (allButtonsOpen) {
                                QLD.accordion.setToggleState(toggleButton, 'closed');
                            } else if (allButtonsClosed) {
                                QLD.accordion.setToggleState(toggleButton, 'open');
                            }
                        }
                    }
                }
            };

            QLD.accordion.handleMutation = function(mutationsList) {
                for (var mutation of mutationsList) {
                    if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                        QLD.accordion.checkToggleState(mutation.target);
                    }
                }
            };

            const accordionTitles = document.querySelectorAll('.qld__accordion__title');
            const observerOptions = {
                attributes: true,
                attributeFilter: ['class']
            };
            
            accordionTitles.forEach(function (accordionTitle) {
                const observer = new MutationObserver(QLD.accordion.handleMutation);
                observer.observe(accordionTitle, observerOptions);
            });

            accordionAllButtons.forEach(function (button) {
                QLD.accordion.checkToggleState(button);
            });
        }
    });
}());