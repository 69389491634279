(function () {
    'use strict';

   function mustache(n,t,e,r){var i,o=mustache,a="";function f(n,t){return n=null!=(n=n[(t=t.pop?t:t.split(".")).shift()])?n:"",0 in t?f(n,t):n}t=Array.isArray(t)?t:t?[t]:[],t=r?0 in t?[]:[1]:t;for(i=0;i<t.length;i++){var s,l="",p=0,g="object"==typeof t[i]?t[i]:{};(g=Object.assign({},e,g))[""]={"":t[i]},n.replace(/([\s\S]*?)({{((\/)|(\^)|#)(.*?)}}|$)/g,function(n,t,e,r,i,c,u){p?l+=p&&!i||1<p?n:t:(a+=t.replace(/{{{(.*?)}}}|{{(!?)(&?)(>?)(.*?)}}/g,function(n,t,e,r,i,c){return t?f(g,t):r?f(g,c):i?o(f(g,c),g):e?"":new Option(f(g,c)).innerHTML}),s=c),i?--p||(u=f(g,u),/^f/.test(typeof u)?a+=u.call(g,l,function(n){return o(n,g)}):a+=o(l,u,g,s),l=""):++p})}return a}
    
    
    var QHDecisionTree = function(container, options = {}) {
        this.container = container;
        this.options = options;
        this.outcomes = container.querySelector('.tree-outcomes')
        this.canvas = container.querySelector('.tree-render');
        this.template = `<fieldset id="item-{{depth}}">
        <legend>{{{html}}}</legend>
        <div class="responses-wrapper">
        <ul class="qld__link-list qld__link-list--inline">
        {{#responses}}
        <li><label class="qld__btn qld__btn--secondary">
        <input type="radio" name="response-{{depth}}" value="{{next}}">
        {{{response}}}
        </label></li>
        {{/responses}}
        </ul>
        </div>
        </fieldset>`;
    
        this.processQuestions(container.querySelector('.tree-questions table'));
        this.externalElements = [];
        this.render();
    }
    QHDecisionTree.prototype.processQuestions = function (table) {
        var rows = table.querySelectorAll('tr');
        this.questions = {};
        var currentQuestion = null;
        var responseId = 0;
        for (var i=0; i< rows.length; i++) {
            if (rows[i].firstChild.tagName == "TD") { 
                var rowData = {
                    qid: rows[i].children[0].innerText.trim(),
                    qtext: rows[i].children[1].innerText.trim(),
                    qhtml: rows[i].children[1].innerHTML,
                    response: rows[i].children[2].innerHTML,
                    next: rows[i].children[3].innerText.trim()
                };
    
                if (rowData.qid) {
                    currentQuestion = {qid: rowData.qid, html: rowData.qhtml, responses: []};
                    this.questions[currentQuestion.qid] = currentQuestion;
                    responseId = 0
                }
                currentQuestion.responses[responseId] = {next: rowData.next, response: rowData.response, id: responseId};
                responseId += 1;
            }
        }
    }
    
    QHDecisionTree.prototype.addQuestion = function (qid) {
        this.stack.push(qid);
        var question = this.questions[qid];
        $(this.canvas).append('<hr>');
        var elem = $(mustache(this.template, $.extend({'depth': this.stack.length},question))).hide().appendTo($(this.canvas)).fadeIn();
        if (qid != "Q1") {
            this.scrollIntoView(elem);
        }
    }
    
    QHDecisionTree.prototype.addOutcome = function (id) {
        var outcome = this.outcomes.querySelector("#"+id);
        $(this.canvas).append('<hr>');
        $(outcome).clone().hide().appendTo($(this.canvas)).fadeIn();
        this.scrollIntoView($(this.canvas).find("#"+id));
    }
    
    QHDecisionTree.prototype.scrollIntoView = function (elem) {
        $('html, body').animate({
            scrollTop: (elem.offset().top - 50)
        }, 500);
    }
    
    QHDecisionTree.prototype.changeHandler = function (e, elem) {
        // Clear the canvas of everything after this question
        for(var i=this.canvas.children.length-1; i > 0; i--) {
            if ($.contains(this.canvas.children[i], elem)) {
                break;
            } else {
                $(this.canvas.children[i]).remove();
            }
        }
        //Set selected class on label
        $(elem).closest('fieldset').find('label').addClass('qld__btn--secondary');
        $(elem).closest('label').removeClass('qld__btn--secondary');
        
        this.externalElements.forEach(function(e) {
            e.hide();
        })
        
        if (elem.value.indexOf('Q') == 0) {
            this.addQuestion(elem.value);
        } else if (elem.value == "Form" && $('#formdiv').length == 1) {
            var elem = $('#formdiv').fadeIn()
            if (this.externalElements.indexOf(elem) < 0) {
                this.externalElements.push(elem);
            }
            this.scrollIntoView(elem);
        } else {
            this.addOutcome(elem.value);
        }
        
    }
    
    QHDecisionTree.prototype.render = function () {
        if (this.stack) {return;}
        this.stack = [];
        this.addQuestion('Q1');
    
        var plugin = this;
        $(this.canvas).on('change', 'input[type=radio]', function(e) {plugin.changeHandler.apply(plugin, [e, this])});
    }
    
    QHDecisionTree.prototype.teardown = function () {
        $(this.canvas).off();
        $(this.canvas).empty();
    }
    

    document.addEventListener('DOMContentLoaded', function(e) {
        const decisionTreeElements = document.querySelectorAll('.qld__decision_tree__wrapper');
        window.debugdecisiontree = [];
        decisionTreeElements.forEach(treeElement => {
            window.debugdecisiontree.push(new QHDecisionTree(treeElement));
        });
    });
    
}());