/**
 * Extra utility functions in QLD namespace.
 * @namespace QLD.utils
 */
(function (QLD) {
    "use strict";

    QLD.utils = QLD.utils || {};

    /**
     * Loads a resource dynamically and executes a callback upon successful loading.
     *
     * @param {Object} resourceInfo - Information about the resource to be loaded.
     * @param {string} resourceInfo.src - The source URL of the resource.
     * @param {string} resourceInfo.element - The type of the resource ('script' or 'link').
     * @param {string} resourceInfo.type - The MIME type such as text/html, text/css (for 'link') or  type of script represented (for 'script').
     * @param {string} [resourceInfo.integrity] - The integrity attribute for resource integrity verification.
     * @param {string} [resourceInfo.crossorigin] - The crossorigin attribute for cross-origin requests.
     * @param {string} [resourceInfo.rel] - The relationship of the resource (for 'link' type, e.g., "stylesheet").
     * @param {string} [resourceInfo.media] - The media attribute for 'link' type resources (default: "screen,print").
     * @param {boolean} [resourceInfo.async] - Indicates whether the resource should be loaded asynchronously (for 'script' type).
     * @param {boolean} [resourceInfo.defer] - Indicates whether the resource execution should be deferred (for 'script' type).
     * @param {function} [resourceInfo.callback] - Callback function to be executed after the resource is loaded.
     * @param {function} [onLoadCallback] - Callback function to be executed after the resource is loaded.
     * @memberof QLD.utils
     */
    QLD.utils.loadResource = function (resourceInfo, onLoadCallback = null) {
        if (!resourceInfo.element) {
            console.error("Resource element is not declared. Please provide a valid element ('script' or 'link').");
            return;
        }

        const existingResource = document.querySelector(`${resourceInfo.element}[src="${resourceInfo.src}"], ${resourceInfo.element}[href="${resourceInfo.src}"]`);
        if (!existingResource) {
            const resource = document.createElement(resourceInfo.element);
            if (resourceInfo.element === 'link') {
                // Default to stylesheet
                resource.href = resourceInfo.src;
                resource.rel = resourceInfo.rel || "stylesheet";
                if (resourceInfo.media) {
                    resource.media = resourceInfo.media;
                }
            } else if (resourceInfo.element === 'script') {
                resource.src = resourceInfo.src;
                if (typeof resourceInfo.async !== 'undefined') {
                    resource.async = resourceInfo.async;
                }
                if (typeof resourceInfo.defer !== 'undefined') {
                    resource.defer = resourceInfo.defer;
                }
            }
            if (resourceInfo.integrity) {
                resource.integrity = resourceInfo.integrity;
            }
            if (resourceInfo.crossorigin) {
                resource.crossOrigin = resourceInfo.crossorigin;
            }

            resource.onload = () => {
                if (typeof resourceInfo.callback === 'function') {
                    resourceInfo.callback();
                }
                if (typeof onLoadCallback === 'function') {
                    onLoadCallback();
                }
            }

            resource.onerror = function () {
                console.error(`Error loading ${resourceInfo.element}:`, resourceInfo.src);
            };

            document.head.appendChild(resource);
        } else {
            if (typeof resourceInfo.callback === 'function') {
                resourceInfo.callback();
            }
            if (typeof onLoadCallback === 'function') {
                onLoadCallback();
            }
        }
    };

    /**
     * Loads resources and executes callbacks based on the specified loading strategy.
     *
     * @param {Object[]} resourceArray - Array of resource information objects.
     * @param {function} [finalCallback] - Callback function to be executed after all resources are loaded.
     * @param {boolean} [concurrent=true] - Indicates whether resources should be loaded concurrently. If set to false, resources will be loaded sequentially.
     * @memberof QLD.utils
     */
    QLD.utils.loadResources = function (resourceArray, finalCallback = null, concurrent = true) {
        let currentIndex = 0;
        let loadedCount = 0;

        function resourceLoaded() {
            loadedCount++;
            if (concurrent && loadedCount === resourceArray.length || !concurrent && currentIndex === resourceArray.length) {
                if (typeof finalCallback === 'function') {
                    finalCallback();
                }
            } else {
                loadNextResource();
            }
        }

        function loadNextResource() {
            const resourceInfo = resourceArray[currentIndex++];
            if (resourceInfo) {
                QLD.utils.loadResource(resourceInfo, resourceLoaded);
            }
        }

        if (concurrent) {
            resourceArray.forEach(loadNextResource);
        } else {
            loadNextResource();
        }
    };

    window.QLD = QLD;

})(window.QLD);
